<template>
	<router-view />
</template>

<script>
	import {
		refreshTokenApi
	} from './api/api'
	export default {
		created() {
			setInterval(() => {
				let token = localStorage.getItem('accessToken')
				if (token) {
					refreshTokenApi().then(res => {}).catch(error => {})
				}
				console.log('update');
			}, 10000)

		}
	}
</script>
<style lang="scss">
	* {
		margin: 0;
		padding: 0;
	}

	@font-face {
		font-family: "OPPO Sans";
		src: url("/src/assets/fonts/OPPOSansWeb2.woff2") format("truetype");
	}

	@font-face {
		font-family: "OPPO Sans-Bold";
		src: url("/src/assets/fonts/OPPOSansWeb2.eot") format("truetype");
	}

	@import url("assets/css/wk.css");
</style>