import request from "@/utils/request";

// 注册
export const registerApi = (data) => {
	return request.post("index/doRegister", data);
};

// 发生登录验证码
export const codeApi = (data) => {
	return request.post("index/captcha", data);
};

// 登录
export const loginApi = (data) => {
	return request.post("index/doLogin", data);
};

// 发送邮箱验证码
export const codeEmailApi = (data) => {
	return request.post("index/sendEmail", data);
};

// 配置信息
export const configApi = () => {
	return request.post("index/config");
};

// 获取银行信息
export const getBankApi = (data) => {
	return request.post("/index/getBanks", data);
};

// 用户信息认证
export const userIdeApi = (data) => {
	return request.post("/user/identity", data);
};

// 获取首页数据
export const homeApi = () => {
	return request.post("/user/home");
};

// 获取用户余额
export const getBalanceApi = () => {
	return request.post("/user/balance");
};

// 获取用户余额明细
export const getBalanceLogApi = (data) => {
	return request.post("/user/balanceLog", data);
};

// 刷新token
export const refreshTokenApi = () => {
	return request.post("/user/doPing");
};

// 退出登录
export const logoutApi = (data) => {
	return request.post("/user/logout", data);
};

// 修改密码
export const setPasswordApi = (data) => {
	return request.post("/user/changePassword", data);
};

// 获取购汇订单列表
export const getBuyOrderListApi = (data) => {
	return request.post("/buy_order/orderList", data);
};

// 获取购汇订单详情
export const getBuyOrderDetailApi = (data) => {
	return request.post("/buy_order/detail", data);
};

// 购汇
export const buyApi = (data) => {
	return request.post("/user/buy", data);
};

// 获取提现订单列表
export const getWithdrawOrderListApi = (data) => {
	return request.post("/withdraw_order/orderList", data);
};

// 获取提现订单详情
export const getWithdrawOrderDetailApi = (data) => {
	return request.post("/withdraw_order/detail", data);
};

// 提现
export const withdrawApi = (data) => {
	return request.post("/user/withdraw", data);
};

// 获取换汇订单列表
export const getExchangeListApi = (data) => {
	return request.post("/exchange_order/orderList", data);
};

// 获取提现订单详情
export const getExchangeOrderDetailApi = (data) => {
	return request.post("/exchange_order/detail", data);
};

// 提现
export const exchangeApi = (data) => {
	return request.post("/user/exchange", data);
};

// 获取用户信息
export const getUserInfoApi = () => {
	return request.post("/user/info");
};

// 获取用户信息
export const updateUserInfoApi = (data) => {
	return request.post("/user/changeInfo", data);
};