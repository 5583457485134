import axios from "axios";
import qs from "qs";
import store from "@/store";
import {
	Notification,
	MessageBox,
	Message,
	Loading
} from "element-ui";
// 创建axios实例
const service = axios.create({
	// axios中请求配置有baseURL选项，表示请求URL公共部分
	baseURL: process.env.VUE_APP_BASE_URL,
	headers: {
		"Content-Type": "application/x-www-form-urlencoded",
	},
	// 超时
	timeout: 60000,
});

// request拦截器
service.interceptors.request.use(
	(config) => {
		const token = window.localStorage.getItem("accessToken");
		config.data = qs.stringify(config.data);
		config.headers.accessToken = token ? token : ""
		console.log(config);
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

// 响应拦截器
service.interceptors.response.use(
	(res) => {
		// 未设置状态码则默认成功状态
		const code = res.data.code;
		const msg = res.data.msg;
		// 获取错误信息
		if (code !== 200) {
			Notification.error({
				title: msg,
			});
			return Promise.reject("error");
		} else {
			return res.data.data;
		}
	},
	(error) => {
		console.log("err" + error);
		let {
			message
		} = error;
		if (message == "Network Error") {
			message = "后端接口连接异常";
		} else if (message.includes("timeout")) {
			message = "系统接口请求超时";
		} else if (message.includes("Request failed with status code")) {
			message = "系统接口" + message.substr(message.length - 3) + "异常";
		}
		Message({
			message: message,
			type: "error",
			duration: 5 * 1000,
		});
		return Promise.reject(error);
	}
);
export default service;