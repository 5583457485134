import Vue from "vue";
import Vuex from "vuex";
import {
	configApi,
	loginApi
} from "@/api/api";
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		globalConfig: {},
		loginState: {
			isLogin: false,
		},
	},
	getters: {},
	mutations: {
		// 设置全局配置
		setGlobalConfig(state, payload) {
			state.globalConfig = payload;
		},
		setLoginState(state, payload) {
			state.loginState = payload;
		},
	},
	actions: {
		async initConfig({
			commit
		}) {
			const res = await configApi();
			commit("setGlobalConfig", res);
		},
		login({
			commit
		}, loginForm) {
			return new Promise((resolve, reject) => {
				loginApi(loginForm)
					.then((res) => {
						res.isLogin = true;
						commit("setLoginState", res);
						localStorage.setItem("accessToken", res.accessToken);
						localStorage.setItem("isLogin", true);
						return resolve(res);
					})
					.catch((error) => {
						return reject();
					});
			});
		},
		logout({
			commit
		}) {
			return new Promise((resolve, reject) => {
				localStorage.removeItem("accessToken");
				localStorage.removeItem("isLogin");
				commit("setLoginState", {});
				return resolve();
			});
		},
	},
	modules: {},
	plugins: [createPersistedState({
		storage: window.sessionStorage, // 或者 localStorage
	})]
});