import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.use({
  i18n: (key, value) => i18n.t(key, value), // 在注册Element时设置i18n的处理方法,可以实现当点击切换按钮后，elementUI可以自动调用.js语言文件实现多语言切换
});
const vue = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
});
// 初始化配置信息
store.dispatch("initConfig");
vue.$mount("#app");
